import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Check } from 'lucide-react';
import { FilterList } from '../filterList';
import Sort from '../../../helpers/sort';
import { getAPCAColor } from '../../../helpers/color';

const FilterItem = ({ tag, filter: { options, ...filter }, updateFilters, hasAdvanceDesign, autoFocus }) => {
  const ref = useRef(null);
  const selectedItems = useMemo(
    () =>
      tag.values.reduce((acc, item) => {
        acc.add(`${filter.queryParam}-${encodeURIComponent(item.id)}`);

        return acc;
      }, new Set()),
    [filter, tag],
  );

  const [sortedOption, setSortedOptions] = useState();

  useEffect(() => {
    if (ref.current && !filter.canFilter) return;
    const selected = [];
    const unselected = [];

    options.forEach((option) => {
      const key = `${filter.queryParam}-${encodeURIComponent(option.value)}`;

      if (selectedItems.has(key)) {
        selected.push(option);
      } else {
        unselected.push(option);
      }
    });
    setSortedOptions(selected.concat(filter.sort ? Sort.alphabetic(unselected) : unselected));
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.canFilter, filter.queryParam, filter.sort, options]);

  return (
    <FilterList
      autoFocus={autoFocus}
      canFilterResults={filter.canFilter || (filter.options && filter.options.length > 12)}
      filterFunction={({ filter: f, options: filterOptions }) =>
        filterOptions.filter((item) => item.name.toLowerCase().indexOf(f.toLowerCase()) >= 0)
      }
      itemTemplate={(option) => {
        const key = `${filter.queryParam}-${encodeURIComponent(option.value)}`;

        return (
          <Flex
            data-testid={key}
            sx={{
              mx: '8px',
              px: '8px',
              mb: '2px',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: selectedItems.has(key) ? 'neutral.20' : 'none',
              borderRadius: selectedItems.has(key) ? '4px' : '0px',
              ':hover': {
                backgroundColor: 'neutral.20',
                cursor: 'pointer',
                borderRadius: '4px',
              },
            }}
            onClick={() => {
              updateFilters({
                filter: {
                  queryParam: filter.queryParam,
                  value: option.value,
                  name: option.name,
                },
                mode: selectedItems.has(key) ? 'remove' : 'add',
              });
            }}
          >
            <Box
              key={key}
              sx={{
                height: ['48px', '48px', '36px'],
                py: ['14px', '14px', '8px'],
                ...(selectedItems.has(key)
                  ? {
                      color: 'text.dark',
                      fontWeight: 500,
                    }
                  : {
                      color: 'text.main',
                      fontWeight: 400,
                    }),
                fontSize: '14px',
                lineHeight: '1.4',
              }}
            >
              {option.name}
            </Box>
            {selectedItems.has(key) && (
              <Box as={Check} size="16px" sx={{ color: getAPCAColor(hasAdvanceDesign).primary }} />
            )}
          </Flex>
        );
      }}
      options={sortedOption}
      sort={filter.sort}
      {...filter}
    />
  );
};

FilterItem.propTypes = {
  tag: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  hasAdvanceDesign: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool.isRequired,
};

export default FilterItem;
