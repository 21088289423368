import { companyStageFormatter, mapHeadCountToInterval } from './stringHelper';

export const filterTagsSelector = ({ allCompanies, query }) => {
  const result = [];
  const { companySlug, q, page, ...rest } = query;
  Object.keys(rest).forEach((key) => {
    switch (key) {
      case 'job_functions':
        result.push({
          queryParam: 'job_functions',
          key: 'job_functions',
          values: query[key].map((filter) => ({ id: filter, name: decodeURIComponent(filter) })),
        });
        break;
      case 'searchable_locations':
        result.push({
          queryParam: 'searchable_locations',
          key: 'searchable_locations',
          values: query[key].map((filter) => ({ id: filter, name: filter })),
        });
        break;
      case 'organization.id':
        result.push({
          queryParam: 'organization.id',
          values: query[key]
            .map((value) => allCompanies.find((i) => i.id.toString() === value.toString()))
            .filter((value) => value !== undefined),
        });
        break;
      case 'organization.slug':
        result.push({
          queryParam: 'organization.slug',
          values: query[key].map((value) => allCompanies.find((i) => i.id.toString() === value.toString())),
        });
        break;
      case 'organization.head_count':
      case 'head_count':
        result.push({
          key,
          queryParam: key,
          values: query[key].map((value) => ({
            id: value,
            name: `${mapHeadCountToInterval(value)} employees`,
            value,
          })),
        });
        break;
      case 'organization.stage':
      case 'stage':
        result.push({
          key,
          queryParam: key,
          values: query[key].map((value) => ({
            id: value,
            name: companyStageFormatter(value),
            value,
          })),
        });
        break;
      default:
        result.push({
          queryParam: key,
          values: query[key].map((filter) => ({ id: filter, name: decodeURIComponent(filter) })),
        });
        break;
    }
  });
  return result;
};
