import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Card, Link } from 'rebass/styled-components';
import { X, Search } from 'lucide-react';
import { useDispatch } from 'react-redux';

import { wlcGetroLogoClicked } from '../../../redux/actions/layout';
import { NetworkSchema } from '../../../schema/network';
import { CompanySchema } from '../../../schema/company';
import { PageLoader } from '../../atoms/pageLoader';
import { SearchInput } from '../../atoms/searchInput';
import { LocationFilter } from '../locationFilter';
import { FilterOption } from './filterOption';
import useLocationSuggestions from '../../../hooks/useLocationSuggestions';
import { getAPCAColor } from '../../../helpers/color';

export const ListFilter = ({
  network,
  company,
  isLoading,
  searchPlaceholder,
  onRemoveTag,
  tags,
  children,
  searchValue,
  rightItem,
  searchRowFilters,
  hideLocationFilter,
  updateFilters,
  filterBySearchTerm,
  origin,
  filterOptions,
  itemCount,
  resetFilters,
  showJobAlert,
  ...rebassProps
}) => {
  const [search, setSearch] = useState(searchValue);
  const dispatch = useDispatch();
  const locationTag = tags.find((tag) => tag.queryParam === 'searchable_locations');
  const allTags = tags
    .filter((item) => item.queryParam !== 'searchable_locations' && item.queryParam !== 'searchable_location_option')
    .reduce((acc, tag) => {
      acc.push(
        ...tag.values.map((value) => ({
          queryParam: tag.queryParam,
          ...value,
        })),
      );

      return acc;
    }, [])
    .flat();

  useEffect(() => {
    if (!searchValue) {
      setSearch('');
    }
  }, [searchValue]);

  const {
    resetSearchTerm,
    locationSuggestionsTerm,
    locationSuggestions,
    loadLocationSuggestions,
    isLocationSuggestionsLoading,
  } = useLocationSuggestions({ network, company, origin });

  const locationResults = useMemo(
    () =>
      locationSuggestions.map((item) => ({
        name: item.value,
        value: item.value,
      })),
    [locationSuggestions],
  );

  const onLogoClick = () => dispatch(wlcGetroLogoClicked({ network, origin: 'powered_by_filters' }));
  const hasAdvancedDesign = network.features.indexOf('advanced_design') !== -1;

  return (
    <Box mt="64px" mb="32px">
      <Card
        tx="card"
        variant="filters"
        className="filters"
        data-testid="list-filter"
        p="16px"
        my="0px"
        mt="0px"
        mb="0px"
        {...rebassProps}
      >
        <Flex flexDirection="column" sx={{ position: 'relative' }}>
          <Flex
            alignItems="flex-start"
            sx={{
              gap: '8px',
              position: 'relative',
              flexDirection: ['column', 'column', 'row'],
              pb: '8px',
            }}
          >
            <SearchInput
              data-testid="search-input"
              value={search}
              type="text"
              placeholder={searchPlaceholder}
              onChange={(value) => {
                setSearch(value);
                filterBySearchTerm({ filter: { queryParam: 'q', value } });
              }}
              maxLength="512"
              height="56px"
              rightIcon={search ? X : null}
              leftIcon={Search}
            />
            {searchRowFilters}
            {!hideLocationFilter ? (
              <>
                <LocationFilter
                  hasAdvancedDesign={hasAdvancedDesign}
                  locations={locationSuggestions}
                  updateFilters={updateFilters}
                  selectedLocations={locationTag?.values ?? []}
                  term={locationSuggestionsTerm}
                  isLocationSuggestionsLoading={isLocationSuggestionsLoading}
                  onSearchChange={(term) => {
                    loadLocationSuggestions({ term });
                  }}
                  resetSearchTerm={resetSearchTerm}
                />
              </>
            ) : null}
          </Flex>
          {isLoading && (
            <Box>
              <PageLoader color={getAPCAColor(hasAdvancedDesign).primary} data-testid="filter-loader" />
            </Box>
          )}
          {!isLoading && (
            <Flex sx={{ gap: ['24px', '8px'], flexDirection: ['column', 'row'] }}>
              <>
                {filterOptions?.length > 0 && (
                  <FilterOption
                    showJobAlert={showJobAlert}
                    hasAdvanceDesign={hasAdvancedDesign}
                    searchValue={searchValue}
                    onInputChange={(value) => {
                      filterBySearchTerm({ filter: { queryParam: 'q', value } });
                    }}
                    total={itemCount}
                    locationFilterOptions={[
                      {
                        name: 'Locations',
                        options: locationResults,
                        queryParam: 'searchable_locations',
                        value: locationTag?.values ?? [],
                        searchValue: locationSuggestionsTerm,
                        canFilter: true,
                        onFilter: (term) => {
                          if (locationSuggestionsTerm !== term) {
                            loadLocationSuggestions({ term });
                          }
                        },
                        isLoading: isLocationSuggestionsLoading,
                      },
                    ]}
                    filterOptions={filterOptions}
                    updateFilters={updateFilters}
                    resetFilters={resetFilters}
                    tags={tags}
                  />
                )}
              </>
              {rightItem && rightItem}
            </Flex>
          )}
        </Flex>
        {allTags.length > 0 && (
          <Flex data-testid="filter-tags" sx={{ gap: '16px', mt: '16px', flexWrap: 'wrap', alignItems: 'center' }}>
            {allTags.map((tag) => (
              <Flex key={tag.id} sx={{ gap: '4px', alignItems: 'center' }} data-testid={`filter_id=${tag.id}`}>
                <Box sx={{ color: 'text.main', fontSize: '14px', lineHeight: '14px', fontWeight: 500 }}>{tag.name}</Box>
                <Box
                  size="16px"
                  sx={{ color: 'neutral.400', cursor: 'pointer' }}
                  as={X}
                  onClick={() => onRemoveTag({ tag, queryParam: tag.queryParam })}
                />
              </Flex>
            ))}
            <Box
              color="text.subtle"
              sx={{
                color: 'text.subtle',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '24px',
                cursor: 'pointer',
              }}
              onClick={() => resetFilters()}
            >
              Clear all
            </Box>
          </Flex>
        )}
      </Card>
      {!network?.features?.includes('hide_getro_in_filters') && (
        <Flex
          sx={{
            pt: '8px',
            justifyContent: 'flex-end',
          }}
        >
          <Link
            href="https://www.getro.com/getro-jobs"
            target="_blank"
            onClick={onLogoClick}
            sx={{
              position: 'relative',
              textAlign: 'right',
              textDecoration: 'none',
              color: '#6B778F!important',
              fontSize: '12px',
              ':hover': {
                color: '#3F495A!important',
              },
            }}
          >
            Powered by Getro
          </Link>
        </Flex>
      )}
    </Box>
  );
};

ListFilter.propTypes = {
  network: NetworkSchema.isRequired,
  company: CompanySchema,
  isLoading: PropTypes.bool,
  searchValue: PropTypes.string,
  onRemoveTag: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tags: PropTypes.array,
  searchPlaceholder: PropTypes.string.isRequired,
  rightItem: PropTypes.node,
  searchRowFilters: PropTypes.node,
  updateFilters: PropTypes.func.isRequired,
  hideLocationFilter: PropTypes.bool.isRequired,
  origin: PropTypes.string,
  filterOptions: PropTypes.array.isRequired,
  filterBySearchTerm: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired,
  resetFilters: PropTypes.func.isRequired,
  showJobAlert: PropTypes.bool,
};

ListFilter.defaultProps = {
  company: null,
  isLoading: false,
  tags: [],
  searchValue: '',
  onRemoveTag: () => {},
  children: null,
  rightItem: null,
  searchRowFilters: null,
  origin: 'jobs',
  showJobAlert: false,
};
