import debounce from 'debounce';
import isEqual from 'lodash.isequal';
import { useDispatch } from 'react-redux';
import { objectToQueryString, sanitizeQuery } from '../helpers/queryString';
import { updatedFilters } from '../redux/actions/filters';
import useEncryptedRouter from './useEncryptedRouter';

const useFilters = (defaultQuery = {}, network) => {
  const router = useEncryptedRouter();
  const dispatch = useDispatch();

  /**
   * This function is used when you want a delay before updating the queryString/API data. is used primary when we have
   * to allow the user to enter some type of text and we want to wait between keypress to save calls
   * @type {wrapper}
   */
  const filterBySearchTerm = debounce((f) => updateFilters(f), 400);

  /**
   * This will add a filter. A filter is defined as query.filter (the encoded values). this should not receive other
   * query params such a 'q' or 'page'
   * @param filter - the filter you want to apply
   * @param oldQuery - query.filter object
   * @returns {*} - a new query.filter object.
   */
  const addFilter = ({ filter, query: oldQuery }) => {
    const query = { ...oldQuery };
    const { queryParam } = filter;
    const { value } = filter;
    const hasParam = Object.prototype.hasOwnProperty.call(query, queryParam);
    const hasValue = Array.isArray(value) ? value.length > 0 : value.toString().trim().length > 0;
    // check to see if the filter contains this param

    if (hasParam && !Array.isArray(value)) {
      const duplicate = query[queryParam].indexOf(value.toString()) > -1;
      if (duplicate) {
        return query;
      }
      const isArray = Array.isArray(query[queryParam]);
      if (isArray && hasValue) {
        query[queryParam] = Array.from(new Set([...query[queryParam], value.toString()]));
      }
      // if we have this param push the value to the array
      // check to see if the property exists and if the value exists
    } else if (hasParam && Array.isArray(value)) {
      query[queryParam] = [...query[queryParam], ...value];
    } else if (!hasParam && hasValue) {
      // if we don't have the value create the param and give it an array with the value
      query[queryParam] = Array.isArray(value) ? value : [value.toString()];
    }
    return query;
  };

  const removeFilter = ({ filter, query }) => {
    const result = { ...query };
    // check to see if the searchQuery has the key we want
    if (query[filter.queryParam] && query[filter.queryParam].length > 0) {
      result[filter.queryParam] = query[filter.queryParam].filter(
        (item) => item.toString() !== filter.value.toString(),
      );
      if (result[filter.queryParam].length === 0) {
        delete result[filter.queryParam];
      }
    }
    return result;
  };

  /**
   * This function is used when a user wants to change there filters. This will reset the page count to 1 and add/remove
   * any of the desired filters
   * @param filter - the filters we want to use
   * @param mode
   * @returns {*} - the new query object
   */
  const trackFilterChange = (filter, newQuery, mode) => {
    const queryParamsToFilterType = {
      'organization.id': 'organization',
      job_functions: 'job_function',
      q: 'query',
      'organization.topic': 'topic',
      searchable_locations: 'location',
      'organization.industry_tags': 'industry_tags',
      industry_tags: 'industry_tags',
      'organization.head_count': 'head_count',
      head_count: 'head_count',
      searchable_location_option: 'onsite_remote',
    };

    const { queryParam, name, value } = filter;
    const filterOptionAdded = name || value;
    let filterName = queryParamsToFilterType[queryParam];
    if (!filterName && filter.queryParam.indexOf('topic') > -1) {
      filterName = 'topic';
    }

    const totalActiveFilters = Object.keys(newQuery).length;
    const clearedOptionsCount = newQuery[filter.queryParam] ? newQuery[filter.queryParam].length : 1;
    const totalSelectedFilters = Object.keys(newQuery).reduce((acc, item) => {
      if (typeof newQuery[item] === 'string') {
        return acc + 1;
      }

      return acc + newQuery[item].length;
    }, 0);

    const optAdded = filter.queryParam === 'q' && value === '' ? router.query.q : filterOptionAdded;
    dispatch(
      updatedFilters({
        filterName,
        mode: filter.queryParam === 'q' && value === '' ? 'remove' : mode,
        filterOptionAdded: optAdded,
        totalActiveFilters,
        totalSelectedFilters,
        clearedOptionsCount,
        network,
      }),
    );
  };

  /*
   * todo: we can clean this function up and seperate it depending on if we are updating the filter params or
   * other query strings
   */
  const updateFilters = ({ filter: updateFilter, mode = 'add' }) => {
    const filter = {
      ...updateFilter,
      value: Array.isArray(updateFilter?.value)
        ? updateFilter?.value.map((item) => item?.toString()?.replace(/'/g, ' '))
        : updateFilter?.value?.toString()?.replace(/'/g, ' ') || '',
    };
    const { query } = router;
    const combinedQuery = { ...defaultQuery, ...query };
    const { q, page, ...restQuery } = combinedQuery;
    let newQueryFilter;
    if (mode === 'remove') {
      newQueryFilter = removeFilter({ filter, query: restQuery });
    } else {
      newQueryFilter = addFilter({ filter, query: restQuery });
    }
    const newQuery = { ...newQueryFilter };
    if (q) {
      newQuery.q = q;
    }
    if (page) {
      newQuery.page = page;
    }
    if (filter.queryParam === 'q') {
      if (filter.value.toString().trim().length === 0) {
        delete newQuery.q;
      } else {
        newQuery.q = filter.value.toString();
      }
    }
    const hasQueryChanged = !isEqual(query, newQuery);
    if (hasQueryChanged) {
      delete newQuery.page;
      trackFilterChange(filter, newQuery, mode);
      router.push({ pathname: router.pathname, query: newQuery });
    }

    return newQuery;
  };

  const resetFilters = (clearedFilter) => {
    const { query } = router;

    if (clearedFilter) {
      trackFilterChange(clearedFilter, query, 'clear');
      delete query[clearedFilter.queryParam];
    } else {
      const { clearedFiltersCount, clearedOptionsCount } = Object.keys(query).reduce(
        (acc, item) => {
          if (item === 'searchable_location_option') {
            acc.clearedOptionsCount += query[item].length < 2 ? 1 : 0;
            acc.clearedFiltersCount += query[item].length < 2 ? 1 : 0;
            return acc;
          }

          if (typeof query[item] === 'string') {
            acc.clearedOptionsCount += 1;
          } else {
            acc.clearedOptionsCount += query[item].length;
          }

          acc.clearedFiltersCount += 1;
          return acc;
        },
        {
          clearedFiltersCount: 0,
          clearedOptionsCount: 0,
        },
      );

      dispatch(
        updatedFilters({
          mode: 'clear_all',
          network,
          clearedFiltersCount,
          clearedOptionsCount,
        }),
      );
    }

    router.push({
      pathname: router.pathname,
      query: clearedFilter ? query : {},
    });
  };

  return {
    filterBySearchTerm,
    updateFilters,
    toFilters: objectToQueryString,
    query: sanitizeQuery(router.query),
    resetFilters,
    addFilter,
  };
};

export default useFilters;
